import { Query, SanityBundles } from "@graphql-types";
import Hero from "@shared/hero";
import Layout from "@shared/layout";
import {
  Container,
  FlexGrid,
  ImageContainer,
  ImageWrapper,
  P,
  Separator,
} from "@util/standard";
import { BreadcrumbPageContext } from "@util/types";
import { graphql, Link } from "gatsby";
import React from "react";
import { Breadcrumb, Image } from "@global";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { pageWidthMargin } from "@util/helper";

interface Props {
  pageContext: BreadcrumbPageContext;
  data: Query;
}


const StyledProductName = styled(P)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 35px;
  }
`;

const BundlesLanding = ({ data, pageContext }: Props) => {
  if (data == null) return null;
  const pageData = data.allSanityBundleLanding.nodes[0];
  const bundles = data.allSanityBundles.nodes;
  const margin = pageWidthMargin();

  if (!pageData) return null;
  return (
    <>
      <Layout>
        <Hero sanityHero={pageData?.hero} />
        <Breadcrumb pageContext={pageContext} />

        <Container
          width={`calc(100% - ${margin} - ${margin})`}
          margin="60px auto"
        >
          <FlexGrid
            gridMargin="45px"
            laptopGridMargin="35px"
            tabletGridMargin="20px"
          >
            {bundles &&
              bundles.map((bundle: SanityBundles) => {
                if (bundle == null) return null;
                return (
                  <Container
                    flexDirection="column"
                    width="33.333%"
                    tabletWidth="50%"
                    mobileWidth="50%"
                  >
                    <Link to={`/bundle/${bundle.slug?.current}`}>
                      <ImageContainer isGift>
                        <ImageWrapper isGift>
                          {bundle.hero?.videoUrl?.image && (
                            <Image
                              imageData={
                                bundle.hero.videoUrl.image.asset
                                  ?.gatsbyImageData
                              }
                              mobileImage={
                                bundle.hero.videoUrl.image?.mobileImage?.asset
                                  ?.gatsbyImageData
                              }
                              objectFit="cover"
                            />
                          )}
                        </ImageWrapper>
                      </ImageContainer>
                      <Container display="flex" flexDirection="column">
                        <StyledProductName
                          noMargin
                          tabletFontSize={13}
                          wordBreak="break-word"
                        >
                          {bundle.title}
                        </StyledProductName>
                        <Separator
                          marginBottom={8}
                          height="8px"
                          opacity={0.4}
                        />
                        <Container justifyContent="space-between">
                          <P noMargin tabletFontSize={14} padding="2px">
                            {bundle.price && <>${bundle.price}</>}
                          </P>
                        </Container>
                      </Container>
                    </Link>
                  </Container>
                );
              })}
          </FlexGrid>
        </Container>
      </Layout>
    </>
  );
};

export default BundlesLanding;

export const query = graphql`
  query BundlesLandingQuery($iban: String) {
    allSanityBundleLanding(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        region {
          iban
        }
        hero {
          ...sanityHero
        }
      }
    }
    allSanityBundles(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        region {
          iban
        }
        slug {
          current
        }
        maxProducts
        price
        title
        description {
          _rawBlocks
        }
        hero {
          ...sanityHero
        }
      }
    }
  }
`;
